import req from "@/utils/request"
//活动列表
export const activity_list = params => req.post("/api/action/list", params)

//添加活动
export const activity_add = params => req.post("/api/action/actionadd", params)

//删除活动
export const activity_del = params => req.post("/api/action/actionDelete", params)

//活动详情
export const activity_xq = params => req.post("/api/action/actioninfo", params)

//介绍模板
export const activity_jsmb = params => req.post("/api/template/info", params)

//修改活动
export const activity_xg = params => req.post("/api/action/actionedit", params)

//添加选手
export const activity_tj = params => req.post("/api/action/useradd", params)

//选手列表
export const activity_xslb = params => req.post("/api/action/userlist", params)

//评论列表
export const commentlist = params => req.post("/api/action/commentlist", params)

//审核评论列表
export const com_status = params => req.post("/api/action/commentstatus", params)

//选手修改
export const player_xg = params => req.post("/api/action/useredit", params)

//选手详情
export const player_xq = params => req.post("/api/action/userinfo", params)

//删除选手
export const player_del = params => req.post("/api/action/userdelete", params)

// 选手分组 添加或者删除
export const group_add_del = params => req.post("/api/action/usercategoryadd", params)

//选手分类列表
export const group_list = params => req.post("/api/action/usercategorylist", params)

//选手介绍模板
export const player_jsmb = params => req.post("/api/template/active", params)

//活动动态列表 
export const articlelist = params => req.post("/api/action/actionarticlelist", params)

//添加活动动态 
export const dynamic_add = params => req.post("/api/action/actionarticleadd", params)

//删除活动动态 
export const dynamic_del = params => req.post("/api/action/actionarticledelete", params)

//修改活动动态 
export const dynamic_xg = params => req.post("/api/action/actionarticleedit", params)

//复制活动 
export const dynamic_copy = params => req.post("/api/action/actioncopy", params)

//虚拟活动数据 
export const actionsham = params => req.post("/api/action/actionsham", params)

//清除活动数据 
export const actionclear = params => req.post("/api/action/actionclear", params)

//站点配置
export const site = params => req.post("/api/common/site", params)

//浏览量每日数据
export const actionStatistics = params => req.post("/api/action/actionStatistics", params)

//批量修改选手状态
export const acuserchangestatus = params => req.post("/api/action/acuserchangestatus", params)

//模版分类
export const category = params => req.post("/api/template/category", params)

// 修改报名状态
export const userjoinstatus = params => req.post("/api/action/userjoinstatus", params)

//图片另存
export const copyImg = params => req.post("/api/action/copyImg", params)

//选手排行
export const playersearch = params => req.post("/api/action/userlist", params)

export const players = parmas => req.post("/api/action/importuser", parmas)

// 选手重置编号
export const resetno = parmas => req.post("/api/action/resetno", parmas)

// 选手操作日志
export const playerAcitonLog = parmas => req.post('/api/action/actionUserOperationLog', parmas)

// 选手投票日志
export const playerVoteLog = parmas => req.post('/api/action/actionUserStatistics', parmas)

//批量修改活动动态状态 
export const actionarticlestatus = parmas => req.post('/api/action/actionarticlestatus', parmas)

export const usergroupchange = parmas => req.post("/api/action/usergroupchange", parmas)

export const thirdInfo = parmas => req.post("/api/user/thirdInfo", parmas)

//获取图片分类接口
export const mediatype = parmas => req.post("/api/common/mediatype", parmas)

// 自定义表单
export const changeSignData = parmas => req.post("/api/action/changeSignData", parmas)

// 自定义表单
export const getSignData = parmas => req.post("/api/action/getSignData", parmas)

// 数据统计

export const actionVoteStatistics = parmas => req.post("/api/action/actionVoteStatistics", parmas)
// 去除选手分组
export const resetGroup = parmas => req.post("/api/action/resetGroup", parmas)
// 无组选手统计
export const countnguser = parmas => req.post("/api/action/countnguser", parmas)
// 一级分组
export const usercategorylistone = parmas => req.post('/api/action/usercategorylistone', parmas)

// 评审信息获取 
export const getreviewApi = parmas => req.post('/api/action/getreview', parmas)

// 评审信息设置
export const setreviewApi = parmas => req.post('/api/action/setreview', parmas)

// 评审评分项设置
export const setscoringApi = parmas => req.post('/api/action/setscoring', parmas)

// 评委新增
export const judgesaddApi = parmas => req.post('/api/action/judgesadd', parmas)

// 评委查询 
export const judgeslistApi = parmas => req.post('/api/action/judgeslist', parmas)

// 评委修改 
export const judgeschangeApi = parmas => req.post('/api/action/judgeschange', parmas)

// 评委打分列表 
export const judgesScoreListApi = parmas => req.post('/api/action/actionScoreList', parmas)

//评审信息下载
export const judgesinfodownApi = parmas => req.get('/api/action/judgesinfodown', parmas)

//评委名下选手 /api/action/judgesuserinfo
export const judgesuserinfoApi = parmas => req.post('/api/action/judgesuserinfo', parmas)

//评分详情
export const judgesUserinfoApi = parmas => req.post('/api/action/scoreInfo', parmas)

//评审文件保存 /api/action/setjudgefile
export const setjudgefileApi = parmas => req.post('/api/action/setjudgefile', parmas)

//评委删除 /api/action/judgesdele
export const judgesdeleApi = parmas => req.post('/api/action/judgesdele', parmas)

//修改图片压缩状态
export const imgCompressStatus = parmas => req.post('/api/action/imgresize', parmas)

//excle 
export const downExcel = parmas => req.post('/api/action/actionUserExcelCreat', parmas)

// 获取小程序二维码
export const getWeappQrCode = params => req.post('/api/applet/getQrimg', params)