<template>
  <div id="app">
    <router-view />
  </div>
</template>
<script>
import { site } from './api/activityList'
export default {
  created() {
    site().then(res => {
      if (res.data.code == 1) {
        sessionStorage.setItem('site', JSON.stringify(res.data.data))
        this.name = res.data.data.name
        document.querySelector('link[rel="icon"]').href = res.data.data.sysico
      }
    })
  },
  watch: {
    '$route.meta.title': function (val) {
      if (localStorage.site) {
        localStorage.setItem('headTitle', val)
        document.title = JSON.parse(localStorage.site).name + localStorage.getItem('headTitle')
      }
    }
  }
}
</script>

<style lang="less">
html::-webkit-scrollbar {
  /*滚动条整体样式*/
  width: 0px; /*高宽分别对应横竖滚动条的尺寸*/
}
html::-webkit-scrollbar-thumb {
  /*滚动条里面小方块*/
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
  background: #535353;
}
html::-webkit-scrollbar-track {
  /*滚动条里面轨道*/
  -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  background: #ededed;
}

body {
  padding-right: 0px !important;
  width: 100vw;
  overflow-x: hidden;
  -moz-user-select: none; /* Firefox私有属性 */
  -webkit-user-select: none; /* WebKit内核私有属性 */
  -ms-user-select: none; /* IE私有属性(IE10及以后) */
  -khtml-user-select: none; /* KHTML内核私有属性 */
  -o-user-select: none; /* Opera私有属性 */
  user-select: none; /* CSS3属性 */
}
*.modal-open {
  overflow-y: scroll;
  padding-right: 0 !important;
}
</style>
