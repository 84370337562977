import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
//请求
import axios from 'axios';
Vue.prototype.axios = axios;
//elmentUI
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
Vue.use(ElementUI);
//公共样式
import './assets/css/reset.css'
import messageUtils from '@/utils/messageUtils'
Vue.prototype.$messageUtils=messageUtils
// 引入echart
import * as echarts from "echarts";
Vue.prototype.$echarts = echarts
//引入富文本框
import VueTinymce from "@packy-tang/vue-tinymce";
Vue.use(VueTinymce)
Vue.config.productionTip = false
// 引入复制到剪切板
import VueClipboard from 'vue-clipboard2'
//引入utils.js
import utils from '@/utils/utils.js'
// 引入楼层插件
const VueScrollTo = require("vue-scrollto")
Vue.use(VueScrollTo)
Vue.use(VueScrollTo, {
  container: 'body',   // 滚动元素
  duration: 500,  // 动画时长
  easing: 'ease',  // 动画曲线
  offset: 0,  // 滚动终点距离父元素顶部距离
  force: true,  // 强制立即执行，即便元素是可见的
  cancelable: true,
  onStart: false,
  onDone: false,
  onCancel: false,
  x: false, // x 轴禁止滚动
  y: true
})
Vue.use(utils)
Vue.use(VueClipboard)
//裁剪
import VueCropper from 'vue-cropper'
Vue.use(VueCropper)
new Vue({
  router,
  store,
  render: h => h(App),
  beforeCreate() {
    Vue.prototype.$bus = this
  },
}).$mount('#app')
