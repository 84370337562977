import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    WorkorderMsg:'',
    iframeUrl:'',
  },
  getters: {
  },
  mutations: {
    SetUrl(state,val){
      state.iframeUrl=val 
    }
  },
  actions: {
    setUrl(content,val){
      content.commit('SetUrl',val)
    }
  },
  modules: {
  }
})
