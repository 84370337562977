// 引入qs
import Qs from "qs"
import axios from "axios";
import router from '../router';
import { dns } from './dns.js'
// 引入ui组件
import { Message } from 'element-ui'

// 请求默认地址
axios.defaults.baseURL = dns;
// 设置请求超时
axios.defaults.timeout = 60000;

// 设置请求拦截器（在请求发送出去之前 带上一些东西）
axios.interceptors.request.use(cofing => {
    return cofing;
}, err => Promise.reject(err))

// 设置响应拦截器 在接收到后端的响应结果以后 统一处理
axios.interceptors.response.use(response => {
    // 拦截数据  统一处理
    let { code, msg } = response;

    if (code === 1) {
        Message({ type: 'success', message: msg })
    }
    return response
}, err => {
    if (401 === err.response.status) {
        router.push("/login")
    }
    return Promise.reject(err) // 返回接口返回的错误信息
})



export default {
    get(url, params = {}) {
        return new Promise((resolve, reject) => {
            axios.get(url, { params })
                .then(response => {
                    resolve(response.data) // 成功
                })
                .catch(err => {
                    reject(err) // 失败
                })
        })
    },
    post(url, params = {}) {
        return new Promise((resolve, reject) => {
            axios.post(url, Qs.stringify(params))
                .then(response => {
                    resolve(response) // 成功
                    if (code === 401) {
                        this.$router.push({ path: '/' })
                    }
                })
                .catch(err => {
                    reject(err) // 失败
                })
        })
    }
}