import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
{
    path: '/login',
    name: 'Login',
    component: () =>
        import ('../views/Login.vue'),
    meta: {
        title: ' - 登录'
    }
},
{
    path: '/',
    name: 'home',
    component: () =>
        import ('../views/Home.vue'),
    meta: {
        title: ' - 首页'
    },
    redirect: '/personal',
    children: [
        {
            path: 'personal',
            name: 'Personal',
            component: () =>
                import ('../views/Personal.vue'),
            meta: {
                title: '首页'
            },
        },
        {
            path: 'manage',
            name: 'Manage',
            component: () =>
                import('@/views/Manage.vue'),
                // import ('../components/manage/ActivitySet.vue'),
            meta: {
                title: ' - 我的活动'
            },
            redirect:'manage/details/activityset',
            children:[
                {
                    path: 'details',
                    name: 'Details',
                    component: () =>
                        import('@/views/Details.vue'),
                        // import ('../components/manage/ActivitySet.vue'),
                    meta: {
                        title: ' - 我的活动'
                    },
                    redirect:'manage/details/activityset',
                    children:[
                        {
                            path: 'activityset',
                            name: 'ActivitySet',
                            component: () =>
                                import ('../components/manage/ActivitySet.vue'),
                            meta: {
                                title: ' - 活动管理'
                            },
                        },
                        {
                            path: 'special',
                            name: 'Special',
                            component: () =>
                                import ('../components/manage/Special.vue'),
                            meta: {
                                title: ' - 专题活动'
                            },
                        } 
                    ]
                },
                {
                    path: 'set',
                    name: 'Set',
                    component: () =>
                        import('@/components/manage/set/Set.vue'),
                    meta: {
                        title: ' - 活动设置'
                    },
                },
           ]
        }, 
        {
            path: 'module',
            name: 'Module',
            component: () =>
                import ('../views/Module.vue'),
            meta: {
                title: ' - 活动模板'
            }
        },
        {
            path: 'upgrade',
            name: 'Upgrade',
            component: () =>
                import ('../views/Upgrade.vue'),
            meta: {
                title: ' - 升级会员'
            }
        },
        {
            path: 'serve',
            name: 'Serve',
            component: () =>
                import ('../views/Serve.vue'),
            meta: {
                title: ' - 定制服务'
            }
        },
        {
            path: 'help',
            name: 'Help',
            component: () =>
                import ('../views/Help.vue'),
            meta: {
                title: ' - 帮助中心'
            }
        },
        {
            path: 'work',
            name: "work",
            meta: {
                title: ' - 工单列表'
            },
            component: () =>
                        import ('../views/Work.vue'),
            redirect: '/work/workorder',
            children: [
                {
                    path: '/work/workorder',
                    name: 'Workorder',
                    component: () =>
                        import ('../views/Work/Workorder.vue'),
                    meta: {
                        title: ' - 工单列表'
                    },
                },
                {
                    path: '/work/workset',
                    name: 'WorkSet',
                    component: () =>
                        import ('../views/Work/WorkSet.vue'),
                    meta: {
                        title: ' - 工单管理'
                    },
                },
            ]
        },
        {
            path: 'info',
            name: "Info",
            component: () =>
                import ('../views/Info.vue'),
            meta: {
                title: ' - 个人中心'
            },
            redirect: '/info/user',
            children:[
                {
                    path: '/ad',
                    name: "Ad",
                    component: () =>
                        import ('../views/Ad.vue'),
                    meta: {
                        title: ' - 广告设置'
                    },
                    redirect: '/ad/adset',
                    children: [{
                            path: 'adset',
                            name: "Adset",
                            component: () =>
                                import ('@/components/ad/Adset.vue'),
                            meta: {
                                title: ' - 广告设置'
                            },
                        },
                        {
                            path: 'adactive',
                            name: "Adactive",
                            component: () =>
                                import ('@/components/ad/Adactive.vue'),
                            meta: {
                                title: ' - 广告设置'
                            },
                        },
                        {
                            path: 'adearnings',
                            name: "Adearnings",
                            component: () =>
                                import ('@/components/ad/Adearnings.vue'),
                            meta: {
                                title: ' - 广告设置'
                            },
                        },
                        {
                            path: 'sharecontent',
                            name: "ShareContent",
                            component: () =>
                                import ('../components/ad/ShareContent.vue'),
                            meta: {
                                title: ' - 广告设置'
                            },
                        },
                    ]
                },
                {
                    path: '/info/userfinance',
                    name: "UserFinance",
                    component: () =>
                        import ('../components/user/UserFinance.vue'),
                    meta: {
                        title: ' - 用户设置'
                    },
                },
                {
                    path: '/info/user',
                    name: "User",
                    component: () =>
                        import ('../components/user/User.vue'),
                    meta: {
                        title: ' - 用户设置'
                    },
                },
                {
                    path: '/info/pawss',
                    name: "Pawssview",
                    component: () =>
                        import ('../components/user/Pawssview.vue'),
                    meta: {
                        title: ' - 密码设置'
                    },
                },
                {
                    path: '/info/appinfo',
                    name: "Appinfo",
                    component: () =>
                        import ('../components/user/Appinfo.vue'),
                    meta: {
                        title: ' - APP绑定'
                    },
                }
            ]
        },
        {
            path: 'specialset',
            name: 'SpecialSet',
            component: () =>
                import ('@/components/manage/SpecialSet.vue'),
            meta: {
                title: ' - 专题设置'
            }
        },
    ]
},

]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})
router.beforeEach((to, from, next) => {
  if(to.path=='/login'){
    next()
  }else{
    if(localStorage.getItem('token')){
        next()
    }else{
        next('/login')
    }
  }
})
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}
export default router
