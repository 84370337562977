
// 智选
export const dns = 'https://api.zhixpoll.cn'

// 拖拽公正
// export const dns = 'https://api.fairvote.cn'

// 新-优选
// export const dns = 'https://api.scyxcm.com'

// 讯志评选
// export const dns = 'https://api.ahzxgp.cn'

//富文本框
export const zh_CN = 'https://user.zhixpoll.cn/langs/zh_CN.js'